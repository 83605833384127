<template>

  <div id="biblioteca">
  
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          Conoce todos documentos publicados en Linkiwork. Podrás revisar, descargar, editar o eliminar alguno si lo requieres.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/biblioteca/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Cargar documento
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 my-3 input-search">
        <input 
            type="search" 
            placeholder="Buscar documento"
            v-model="filter.search"
            ref="searchInput"
            class="form-control input-custom"
            @keyup.delete="eraseText()">
            <span class="input-search-icon">
              <font-awesome-icon icon="search"/>
            </span>
      </div>
    </div>

    <Loading 
      v-if="loading"
      pixeles="150px"
    />

    <template v-if="!loading">   
      <div class="row">
        <div class="col-12">
          <div class="table-container">
            <table 
              v-if="!loading && document_list.length !== 0"
              class="table table-custom table-borderless table-custom-responsive mb-0">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col" class="text-center">Tipo de archivo</th>
                  <th scope="col" class="text-center">Categoría</th>
                  <th scope="col" class="text-center">Fecha de subida</th>
                  <th scope="col" class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
              <!-- :class="doc.id_estado_fk=='1'?'active':'disabled'" -->
                <tr v-for="(doc, k) in document_list" :key="k" > 
                  <td class="th th-nombre">
                    <font-awesome-icon
                      class="name-icon ban"
                      v-if="doc.id_estado_fk != '1'"
                      :icon="['fas', 'ban']"/>
                      {{ doc.nombre }}
                  </td>
                  <td class="th th-tipo-archivo text-center">{{ doc.url_documento.split(".")[doc.url_documento.split(".").length - 1]}}
                  </td>
                  <td class="th th-categoria text-center">{{ doc.categoria[0].nombre }}</td>
                  <td class="th th-fecha-subida text-center">{{ dateFormatymdhours(doc.fecha_creacion) }}</td>
                  <td class="th th-accion text-center">
                    <div 
                      class="dropdown dropdown-custom d-flex justify-content-center">
                      <button 
                        class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                        type="button" 
                        data-bs-toggle="dropdown">
                        <font-awesome-icon icon="ellipsis-vertical"/>
                      </button>
                    <ul 
                      class="dropdown-menu"
                      :class="doc.id_privilegio_fk == '1' && 'unactive'">
                      <li>
                        <a
                          href="javascript:"
                          class="dropdown-item" 
                          @click="changeDocStatus(doc.id_documento, doc.id_estado_fk)">
                          <font-awesome-icon
                            class="pe-2"
                            :class="doc.id_estado_fk == '1' ? 'color-main' : 'color-secondary'"
                            :icon="doc.id_estado_fk == '1' ?  ['fas', 'ban']:['fas', 'check']"/>         
                            {{ doc.id_estado_fk == "1" ? "Desactivar" : "Activar" }}
                        </a>
                      </li>
                      <li>
                        <a 
                          class="dropdown-item" 
                          :href="doc.url_documento" 
                          target="_blank">
                          <font-awesome-icon
                            class="pe-2 color-main"
                            icon="eye"/>
                          Vista previa
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:"
                          class="dropdown-item"
                          @click="openEditDocument(doc.id_documento)">
                          <font-awesome-icon
                            class="pe-2 color-main"
                            icon="pen"/>
                          Editar
                      </a>
                      </li>
                      <li>
                        <a
                          href="javascript:"
                          class="dropdown-item"
                          @click="openQuestionDelete(doc)">
                          <font-awesome-icon
                            class="pe-2 color-main"
                            icon="trash-alt"/>
                          Eliminar
                      </a>
                      </li>
                    </ul>
                  </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <SinResultados
              msg="Aceptar" 
              custom-click 
              @click="nuevaBusqueda"  
              v-if="!loading && document_list.length === 0"/>

          </div>
          <Question
            v-if="open_question_modal"
            :msg="question_modal_msg"
            :hideCancel="false"
            @cancel="cancelQuestion"
            @accept="acceptQuestion"
          />
          <Status
            v-if="open_modal_status"
            :msg="modal_status_msg"
            :status="modal_status"
            @close="closeStatus"
          />
        </div>
      </div>
    </template>
  </div>

</template>

<script>
  import Question from "../Modales/Question.vue";
  import Status from "../Modales/Status.vue";
  //import {  } from "@/apollo/queries";
  //import { DELETE_SAFETY_DOC } from "@/apollo/mutations";
  import { mapActions, mapState } from "vuex";

  //FF
  import Loading from "@/components/Loading.vue";
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: { 
      Question, 
      Status, 

      //FF
      Loading,
      SinResultados
    },
    data() {
      return {
        open_question_modal: false,
        question_modal_msg: "",
        open_modal_status: false,
        modal_status_msg: "",
        modal_status: true,
        toggle_switch_btn: false,
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        library_module_id: "16",
        categories_list: [],
        document_list: this.library_docs_list,
        doc_to_delete: null,
        no_results: false,
        months: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        modulos: this.$ls.get("modules"),
        option: "",

        loading: true,
        filter: { 
          search: ''
        },
      };
    },
    async created() {
      await this.getBooks();
    },
    computed: {
      ...mapState("docsModule", ["library_docs_list"]),
      count () {
        return this.$store.state.docsModule.library_docs_list.length
      },
    },
    watch: {
      "filter.search"(value) {
        if (value.length >= 3) {
          var search = value.toLowerCase();
          let search_result = this.library_docs_list.filter((doc) =>
            doc.nombre.toLowerCase().includes(search)
          );
          if (search_result.length > 0) {
            this.document_list = [];
            search_result.forEach((item) => {
              this.document_list.push(item);
              this.no_results = false;
            });
          } else {
            this.document_list = [];
            this.no_results = true;
          }
        }
        if(value === '') {
          this.document_list = this.library_docs_list;
        }
      },
      count () {
          this.document_list = this.library_docs_list;
      }
    },
    methods: {
      ...mapActions("docsModule", ["getDocsLibList", "deleteDocLibAction", "editDocLibAction", "cambiaEstadoDocBiblioteca"]),
      nuevaBusqueda() {
        this.filter.search = '';
        this.$refs.searchInput.focus();
        this.getBooks();
      },
      openQuestionDelete(doc) {
        this.doc_to_delete = doc;
        this.open_question_modal = true;
        this.question_modal_msg = "¿Estás seguro de eliminar este archivo?";
      },
      cancelQuestion() {
        this.open_question_modal = false;
      },
      // eliminar documento
      async acceptQuestion() {
        this.loading = true;
        console.log("acá eliminado en modulo de biblioteca");
        this.loading = true;
        let data_ = {
          id_documento: this.doc_to_delete.id_documento,
          id_empresa: this.id_empresa,
          id_eliminador: this.$ls.get("user").id_usuario,
        }
        await this.deleteDocLibAction(data_).then(res=>{
          if(res){
            this.loading = false;
            this.$toast.open({
              message: "Se elimino correctamente el documento en la sección de seguridad ",
              type: "success",
              duration: 5000,
              position: "top-right",
            });
            this.open_question_modal = false;
          }
          else{
            this.loading = false;
            this.$toast.open({
              message: 'Ocurrió un error al eliminar el documento, intentalo nuevamente',
              type: 'error',
              duration: 5000,
              position: 'top-right'
            });
            this.open_question_modal = false;
          }
        }).catch((err)=>{
          console.log("Error: ", err);
          this.loading = false;
          this.$toast.open({
            message: 'Ocurrió un error al eliminar el documento, favor contactarse con el administrador',
            type: 'error',
            duration: 5000,
            position: 'top-right'
          });
          this.open_question_modal = false;
        });
        // this.$apollo
        //   .mutate({
        //     mutation: DELETE_SAFETY_DOC,
        //     variables: {
        //       id_documento: this.doc_to_delete.id_documento,
        //       id_empresa: this.id_empresa,
        //       id_eliminador: this.$ls.get("user").id_usuario,
        //     },
        //   })
        //   .then((res) => {
        //     var resp = res.data.eliminarDocumento;
        //     this.loading = false;
        //     if (resp.id_documento) {
        //       this.deleteDocLibAction(resp.id_documento);
        //       setTimeout(() => {
        //         this.document_list = this.library_docs_list;
        //       }, 1000);
        //       this.open_question_modal = false;
        //       this.open_modal_status = true;
        //       this.modal_status_msg = "Documento eliminado con éxito";
        //       this.modal_status = true;
        //     }
        //   })
        //   .catch((err) => {
        //     console.log("err", err);
        //     this.loading = false;
        //     this.modal_status = false;
        //     this.open_modal_status = true;
        //     this.modal_status_msg =
        //       "No se ha podido eliminar el documento, intenta nuevamente";
        //   });
      },
      closeStatus() {
        this.open_modal_status = false;
      },
      openEditDocument(idDoc) {
        this.$router
          .push({
            name: "library-edit",
            params: { id: idDoc },
          })
          .catch(() => {
            return;
          });
      },
  
      async getBooks(text) {
        if (text == undefined) text = '';
        this.loading = true;
        this.document_list = [];
        let variables_search = {
          id_empresa: this.id_empresa,
          id_categoria: "0", //Categoría: "Todas"
          id_modulo: this.library_module_id,
          plataforma: "2", //Web
          nombre: text,
        };
        await this.getDocsLibList(variables_search);
        this.document_list = this.library_docs_list;
        this.loading = false;
      },
      async changeDocStatus(id_documento, id_estado_fk) {
        this.loading = true;
        let status = id_estado_fk == "1"? "3" : "1";
        let data_ = {
          id_documento: id_documento,
          id_empresa: this.id_empresa,
          id_modificador: this.$ls.get("user").id_usuario,
          estado: status,
        }
        await this.cambiaEstadoDocBiblioteca(data_).then(res=>{
          if(res){
            this.loading = false;
            this.$toast.open({
              message: "Se deshabilito correctamente el documento, este documento ya no estará visible para los colaboradores",
              type: "success",
              duration: 5000,
              position: "top-right",
            });
            this.open_question_modal = false;
          }
          else{
            this.loading = false;
            this.$toast.open({
              message: 'Ocurrió un error al intantar deshabilitar el documento, intentalo nuevamente',
              type: 'error',
              duration: 5000,
              position: 'top-right'
            });
            this.open_question_modal = false;
          }
        }).catch((err)=>{
          console.log("Error changeDocStatus, error: ", err);
          this.$toast.open({
              message: 'Ocurrió un error al intantar deshabilitar el documento, favor contactate con el administrador',
              type: 'error',
              duration: 5000,
              position: 'top-right'
          });
            this.open_question_modal = false;
        });
      // await this.$apollo
      //     .mutate({
      //       mutation: CHANGE_SAFETY_DOC_STATUS,
      //       variables: {
      //         id_documento: id,
      //         id_empresa: this.id_empresa,
      //         id_modificador: this.$ls.get("user").id_usuario,
      //         estado: status,
      //       },
      //     })
      //     .then((res) => {
      //       var resp = res.data.cambiarEstadoDocumento;
      //       this.loading = false;
      //       if (resp.id_documento) {
      //         resp.id_modulo = this.safety_module_id;
      //         // console.log(resp);
      //         this.editDocLibAction(resp);
      //         this.open_question_modal = false;
      //         this.$toast.open({
      //             message:'Documento '+success_msg+' correctamente.',
      //             type:'success',
      //             duration:6000,
      //             position:'top-right'
      //           });
      //       }
      //     })
      //     .catch((err) => {
      //       console.log("err", err);
      //       this.loading = false;
      //       this.$toast.open({
      //             message:'Ocurrió un error al '+err_msg+' el documento, intentalo nuevamente.',
      //             type:'error',
      //             duration:0,
      //             position:'top-right'
      //           });
      //     });
      },
      eraseText() {
        if (this.filter.search.length == 0) {
          this.document_list = this.library_docs_list;
          this.no_results = false;
        }
      },
      handleDate(fecha) {
        var date = new Date(fecha);
        var day = date.getDate();
        var month = this.months[date.getMonth()];

        if (day < 10) {
          day = "0" + day.toString();
        }

        return day + " de " + month;
      },
      //abrir opciones
      openOption(id) {
        setTimeout(() => {
          this.option = id;
        }, 10);
      },
      outOption() {
        if (this.option != "") {
          this.option = "";
        }
      },
    },
  };
</script>